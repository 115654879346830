<template>
  <div>
    <v-file-input
      ref="file"
      v-show="false"
      label="Choose Images"
      outlined
      dense
      capture="camera"
      accept="image/*"
      prepend-icon="mdi-camera"
      clearable
      hide-details
      v-model="files"
      multiple
      :loading="loading"
      :disabled="disabled"
      @change="filesChanged"
    ></v-file-input>

    <v-sheet class="d-flex mx-auto">
      <v-slide-group multiple show-arrows>
        <v-slide-item class="my-auto" :key="0">
          <div class="text-center">
            <v-btn ripple class="mx-2" @click="chooseFile" depressed rounded icon>
              <v-icon color="primary" x-large>mdi-camera-outline</v-icon>
            </v-btn>
            <div
              v-if="previewImages && previewImages.length > 0"
              class="text-caption font-weight-light mx-2"
            >
              <div>
                {{ `${previewImages.length} files` }}
              </div>
              <div>
                {{ ` ${totalSize}/${fileSize(maxSize)} max` }}
              </div>
            </div>
          </div>
        </v-slide-item>
        <v-slide-item v-for="(img, index) in previewImages" :key="index + 1">
          <div class="text-center">
            <div class="my-auto" style="min-height: 100px">
              <v-img
                @click="
                  selectedImg = img;
                  imgDialog = true;
                "
                class="ma-2"
                contain
                max-height="80"
                max-width="150"
                :src="img"
              ></v-img>
            </div>
            <v-btn :disabled="loading" @click="remove(index)" icon
              ><v-icon>mdi-delete-outline</v-icon></v-btn
            >
          </div>
        </v-slide-item>
      </v-slide-group>
    </v-sheet>

    <v-dialog style="z-index: 1000" v-model="imgDialog">
      <v-card v-if="selectedImg">
        <v-card-title class="justify-end">
          <div>
            <v-btn @click="imgDialog = null" icon
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </div>
        </v-card-title>
        <v-card-text class="d-flex">
          <v-img class="ma-2" contain width="80%" :src="selectedImg"></v-img>
        </v-card-text>
      </v-card>
      <div v-else class="text-subtitle-2">No images selected</div>
    </v-dialog>
  </div>
</template>

<script>
import { fromBlob } from "image-resize-compress";

export default {
  props: {
    value: {
      type: [Array, Object],
    },
    compress: {
      type: Boolean,
      required: false,
      default: true,
    },
    minFileSize: {
      type: Number,
      required: false,
      default: 300000, //300kb
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    maxSize: {
      type: Number,
      required: false,
      default: 10485760,
    },
  },
  computed: {
    previewImages() {
      const previews = [];
      if (this.compressedImages && this.compressedImages.length > 0) {
        for (const compressed of this.compressedImages) {
          const fileUrl = URL.createObjectURL(compressed);
          previews.push(fileUrl);
        }
      }
      return previews;
    },
    totalSize() {
      let total = 0;
      if (this.compressedImages && this.compressedImages.length > 0) {
        total = this.compressedImages.reduce((sum, i) => {
          sum += i.size;
          return sum;
        }, 0);
      }
      return this.fileSize(total);
    },
  },
  data: () => {
    return {
      files: null,
      compressedImages: [],
      selectedImg: null,
      imgDialog: false,
    };
  },
  methods: {
    filesChanged() {
      // quality value for webp and jpeg formats. percentage
      const quality = 100;
      // output width. 0 will keep its original width and 'auto' will calculate its scale from height.
      const width = 860;
      // output height. 0 will keep its original height and 'auto' will calculate its scale from width.
      const height = "auto";
      // file format: png, jpeg, bmp, gif, webp. If null, original format will be used.
      const format = null;

      for (const file of this.files) {
        if (file.size < this.minFileSize) {
          console.log("file already too small, skip compression");
          this.compressedImages.push(file);
        } else if (!this.compress) {
          this.compressedImages.push(file);
        } else {
          console.log("original file size: " + file.size);
          fromBlob(file, quality, width, height, format).then((blob) => {
            const fileType = blob.type.split("/");
            const extension =
              fileType && fileType.length > 1 ? fileType[1] : "jpg";
            blob.name = blob.name ? blob.name : `camera.${extension}`;
            this.compressedImages.push(blob);
          });
        }
      }

      this.files = [];
      this.$emit("input", this.compressedImages);
    },
    remove(index) {
      console.log("remove index: ", index);
      this.compressedImages.splice(index, 1);
      this.files.splice(index, 1);

      this.$emit("input", this.compressedImages);
    },
    fileSize(size) {
      if (size) {
        var i = Math.floor(Math.log(size) / Math.log(1024));
        return (
          (size / Math.pow(1024, i)).toFixed(2) * 1 +
          " " +
          ["B", "kB", "MB", "GB", "TB"][i]
        );
      }
      return "0 B";
    },
    chooseFile() {
      console.log(this.$refs.file.$el);
      const fileElement =
        this.$refs.file.$el.querySelector('input[type="file"]');
      console.log(fileElement);
      fileElement.click();
    },
  },
};
</script>

<style scoped>
.nowrap {
  white-space: nowrap;
}
</style>
